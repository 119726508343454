import { create } from "zustand";
import { persist } from 'zustand/middleware';
import { UserDetails } from "./interfaces/UserDetails";
import { StoreNames } from "./StoreNames";
// Create type for custom hook
export type Workflows = 'individual' | 'organization' | null


type AuthState = {
    details: UserDetails |null;
    setDetails:(details:UserDetails)=>void;
    token: string | null;
    tokenValidated: boolean;
    loggedIn: () => boolean;
    setToken: (token: string | null) => void;
    logOut:()=>void;
};


// Create custom hook with a property and method to share globally Work Item: #24080
//
//  awardee:  user creating the PIF
//  org: string property representng Name of Prime Contractor, Grantee, or Recipient
//           from AwardInfo Component
//  setAwardee: method uses set function to change awardee Name 


export const authStore = create<AuthState>()(
    persist(
        (set,get) => ({
            details:null,
            token: null,
            authToken: null,
            tokenValidated: false,
            setDetails:(details:UserDetails)=>set(()=>({details:details})),
            loggedIn: () => get().tokenValidated===true && get().token===null,
            setToken: (token: string|null) => set(() => ({ token: token})),
            logOut:()=>{
                set(()=>({token:null}));
                localStorage.clear();
        }}),
        { name: StoreNames.AuthToken, getStorage: () => localStorage })
);




