import { create } from "zustand";
import { persist } from 'zustand/middleware';
import { FORM_STATES, FormStep, resetFormSteps } from "./interfaces/FormStep";
import { ICountry } from "../../Interfaces/ICountry";
import { getCountries } from "../../Services/Api";
import { StoreNames } from "./StoreNames";
// Create type for custom hook
export type Workflows = 'individual' | 'organization' | null

type CountryState = {
    countries: ICountry[] ;
    updateCountries: ()=>void;
};


// Create custom hook with a property and method to share globally Work Item: #24080
//
//  awardee:  user creating the PIF
//  org: string property representng Name of Prime Contractor, Grantee, or Recipient
//           from AwardInfo Component
//  setAwardee: method uses set function to change awardee Name 


export const useCountryStore = create<CountryState>()(
    persist(
        (set) => ({
            countries:[],
            storeFormType: "",
            updateCountries: () =>{
                getCountries()
                .then((x:ICountry[])=>set(() => ({countries : x?.filter(y=>y.country_name)
                    .slice()
                    .sort((a:ICountry,b:ICountry) =>a.country_name.localeCompare(b.country_name))
                    .map(x=>{
                        if(x.country_code && !x.country_code.includes("+")){
                            x.country_code = "+"+x.country_code
                        } 
                            return x;
                    })})))
                }
        }),
        { name: StoreNames.Country, getStorage: () => localStorage })
);

