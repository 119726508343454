import { Download } from '@mui/icons-material';
import React, { useState } from 'react'
import axios from 'axios';
import pdf_icon from '../../../assets/images/svg/pdf.svg';
import IconButton from '@mui/material/IconButton';

    export default function PDFViewer ( props: {pifid:string} ) {

    const pifID = props.pifid;
    
     const [inputData, setInputData] = useState(
        {
            "pifID": pifID,
          });
   

const ViewPifPDF = async () => {

try{
    
     const getPDFURL: string = process.env.REACT_APP_API_URL + "/pif/populatePDFPIF";
        
        const response = await axios.post(getPDFURL, inputData, { 
            responseType: 'blob',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
                'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
            },
        });

        if(response.status === 200) {
            const pdfBlob = new Blob([response.data], {type: 'application/pdf'})

            const url = URL.createObjectURL(pdfBlob);

            window.open(url, '_blank');

        } else {
            console.error('Failed to View PDF')
        } 
    } catch(error){
        console.error('Error during PDF download', error)
    }
};
    return (
        <IconButton onClick={ViewPifPDF}>
        <img className='message_icon'src={pdf_icon} alt="PDF Icon" />
      </IconButton>

    );

};
