import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
// import "../subaward/SubAwardSubmission.css";
import "./ReVetSubmission.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import { useState, useMemo, } from 'react';
import icon_people from "../../assets/images/svg/people.svg";
import icon_person from "../../assets/images/svg/person.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faLandmark } from '@fortawesome/free-solid-svg-icons';

import NavigationBar from "../common/nav/NavMenu";
// import { PIFContext } from "./awardInfo/AwardInfo";
import { Outlet, useLocation } from "react-router-dom";

import { useNavStore } from "../stores/NavStore";
import BreadCrumps from "../common/nav/BreadCrumps";
import SelectFormType from "../subaward/selectFormType/SelectFormType";
import FormNavigation from "../subaward/formNavigation/FormNavigation";
import { authStore } from "../stores/AuthStore";
import { RouteConstants } from "../../App";
import { Link, useNavigate } from "react-router-dom";





export default function ReVetSubmission() {

  const { storeFormType } = useNavStore();
  // TESTING
  console.log("storeFormType Object:" + storeFormType);
  const items: { value: string, label: string, iconName: string, iconClass: string }[] = [
    { value: 'organization', label: 'Organization', iconName: icon_people, iconClass: "bi-people" },
    { value: 'individual', label: 'Individual', iconName: icon_person, iconClass: "bi-person" }
  ];


  // const {setFormType,enabledForms,formType,switchForm} = FormStore();
  // const [toggleAwardeeAndFormNav,setToggleAwardeeAndFormNav] = useState(true);
  // Object for left nav links
  const navLinks =
  {
    linkState: 'Awardee Type',
    pageTitle: "Select Award",
    breadCrumbTextLink: 'Select Awardee',
    homeLinkText: 'PVSportal',
  };


  const breadCrumbLinkText: string = "Select Awardee Type";

  const [value, setValue] = useState<string | null>(null);

  const navigate = useNavigate();
  const { clearNavStore, updateAwardType } = useNavStore();



  // Different text for breadcrumbs - changes with each step
  let breadCrumbTextLink = 'Select Awardee Type';


  // Other State Vars
  // const [ initalPage, setInitialPage] = useState(true);

  // const [selectedRadioBtn, setSelectedRadioBtn ] = useState('');
  // const [radioBtnStatus, setRadioBtnStatus ] = useState(false);
  // const [ btnVisibility, toggleBtnVisibility] = useState(false);


  // const orgId:any = document.getElementById("awardeeorganization");
  // const orgId:any = document.querySelector("#awardeeorganization");


  // const indId:any = document.getElementById("awardeeindividual");
  const indId: any = document.querySelector("#awardeeindividual");


  //*Get location 
  const urlLocation = useLocation();
  function getCurrentUrl() {
    return urlLocation.pathname as string
  }
  // Set state to hide/show elements based on SUB, PRIME, or NONAWARD
  const [showRevetSubmission, setShowRevetSubmission] = useState(false);


  useMemo(() => {
    // Check for Revet---not needed?
    if (getCurrentUrl().includes("revet")) {
      setShowRevetSubmission(true);
      console.log("Ran function inside memo");
    }
    // return getCurrentUrl();
  }, [showRevetSubmission]); // Dependency array: recompute if 'data' changes

  return (
    <>
      <div className="me-auto navContainer">
        <BreadCrumps />
      </div>

      <div className="row mb-3">
        <div className="col-1">
        </div>
        <div className="col-10">
          <div className="row"><h3 className="revet-heading text-center"> Re-Vetting Submission </h3></div>
          <div className="row">
          <p className="mb-2 revet-text"> Re-Vetting is generally required for 3 reasons: </p>
            <ol style={{width: "75%"}}>
              <li> Vetting Expiration </li>
              <li> Award Modification </li>
              <li> Change of Key Individuals </li>
            </ol>
          </div>
        </div>
        <div className="col-1">
        </div>
      </div>
      <div className="row mt-3">
          <div className="row"><h3 className="revet-heading text-center"> Select Award Type for Vetting </h3></div>
          <div className="row d-flex justify-content-center">
          <div className="col-10 revet-background d-flex justify-content-around">
          <button type="button" className="btn-style mt-3" onClick={() => {
                clearNavStore();
                updateAwardType("primeaward");
                navigate(RouteConstants.PRIME_AWARD_INFO,  {state:{pageFlow : "revet"}});
              }}>
              <img src="Prime_Tile.png"/>
          </button>

            <button type="button" className="btn-style mt-3" onClick={() => {
                    clearNavStore();
                    updateAwardType("subaward");
                    navigate(RouteConstants.SUB_AWARD_INFO, {state:{pageFlow : "revet"}});

                  }}>
                    <img src="Subaward_Tile.png"/>
              </button>

              <button type="button" className="btn-style mt-3" onClick={() => {
                clearNavStore();
                updateAwardType("nonaward");
                navigate(RouteConstants.NON_AWARD_INFO, {state:{pageFlow : "revet"}});

              }}>
                <img src="Non_award_tile.png"/>
              </button>
          </div>
          </div>
      </div>
        <div>


          {/* below outlet handles subroutes */}
          <Outlet />

        </div>
    </>
  );
}