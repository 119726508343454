import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/common/header/Header";
import Footer from "./components/common/footer/Footer";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Auth from "./components/Login/Auth";
import Privacy from "./components/Privacy/Privacy";
import RulesOfBehavior from "./components/RulesOfBehavior/RulesOfBehavior";
import SubAwardSubmission from "./components/subaward/SubAwardSubmission";
import OrganizationInfo from "./components/subaward/organization/OrganizationInfo";
import AwardInfo from "./components/subaward/awardInfo/AwardInfo";
import ReviewAndSubmit from "./components/subaward/reviewAndSubmit/ReviewAndSubmit";
import KeyIndividuals from "./components/subaward/organization/KeyIndividuals";
import About from "./components/about/AboutComponent";
import Contact from "./components/contact/ContactComponent";
import Success from "./components/subaward/reviewAndSubmit/Success";
import Alt_login from "./components/Login/Alt_login";
import TestDashboard2 from "./components/UserDashBoard/Dashboard/TestDashboard2";
import PrimeAward from "./components/primeawards/PrimeAward";
import NonAward from "./components/nonawards/NonAward";
import ErrorPages from "./components/common/ErrorPage/Error";
import Help from "./components/help/Help";
import ReVetSubmission from "./components/revet/ReVetSubmission";

function App() {
  return (
    <div className="container-fluid mainWrapper Background">
      <div className=" headerRegion">
        <Header />
      </div>
      <div className=" routingArea">
        <BrowserRouter>
          <Routes>
            <Route path={RouteConstants.HOME_PAGE} element={<RulesOfBehavior />} />
            <Route path={RouteConstants.ROB} element={<RulesOfBehavior />} />
            <Route path={RouteConstants.LOGIN} element={<Login />} />
            <Route path={RouteConstants.AUTH} element={<Auth />} />
            <Route path={RouteConstants.SUPPORT} element={<Login />} />
            <Route path={RouteConstants.PRIVACY} element={<Privacy />} />
            <Route path={RouteConstants.MY_USAID} element={<Login />} />
            <Route path={RouteConstants.ABOUT} element={<About />} />
            <Route path={RouteConstants.HELP} element={<Help />} />

            
            <Route path={RouteConstants.SUB_AWARD} element={<SubAwardSubmission />} >
              {/* <Route path={RouteConstants.SUBAWARD_AWARDEE} element={<SelectFormType />} /> */}
              <Route path={RouteConstants.SUB_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.SUBAWARD_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.SUBAWARD_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.SUBAWARD_REVIEW} element={<ReviewAndSubmit />} />
            </Route>

            {/* Primeawards */}
            <Route path={RouteConstants.PRIME_AWARD} element={<PrimeAward />} >
              {/* <Route path={RouteConstants.SUBAWARD_AWARDEE} element={<SelectFormType />} /> */}
              <Route path={RouteConstants.PRIME_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.PRIME_AWARD_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.PRIME_AWARD_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.PRIME_AWARD_REVIEW} element={<ReviewAndSubmit />} />
            </Route>

           
            <Route path={RouteConstants.NON_AWARD} element={<NonAward/>} >
              {/* <Route path={RouteConstants.SUBAWARD_AWARDEE} element={<SelectFormType />} /> */}
              <Route path={RouteConstants.NON_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.NON_AWARD_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.NON_AWARD_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.NON_AWARD_REVIEW} element={<ReviewAndSubmit />} />
            </Route>

            <Route path={RouteConstants.REVET_SUBMISSION} element={<ReVetSubmission/>} >
              {/* <Route path={RouteConstants.SUBAWARD_AWARDEE} element={<SelectFormType />} /> */}
              {/* New Routes for Revetting Prime Award, Subaward, Non-Award */}
              <Route path={RouteConstants.REVET_PRIME_AWARD} element={<PrimeAward />} />
              <Route path={RouteConstants.REVET_SUBAWARD} element={<SubAwardSubmission />} />
              <Route path={RouteConstants.REVET_NON_AWARD} element={<NonAward />} />

              {/* <Route path={RouteConstants.REVET_SUBMISSION_AWARD_INFO} element={<AwardInfo />} />
              <Route path={RouteConstants.REVET_SUBMISSION_KEY_INDIVIDUAL} element={<KeyIndividuals />} />
              <Route path={RouteConstants.REVET_SUBMISSION_ORGANIZATION} element={<OrganizationInfo />} />
              <Route path={RouteConstants.REVET_SUBMISSION_REVIEW} element={<ReviewAndSubmit />} /> */}
            </Route>

            <Route path={RouteConstants.SUCCESS} element={<Success />} />
            <Route path={RouteConstants.DASHBOARD} element={<TestDashboard2 />} />

            {/* <Route path={RouteConstants.ALT_LOGIN} element={<Alt_login />} /> */}
            <Route path={RouteConstants.ALT_LOGIN} element={<Alt_login />} />

            <Route path={RouteConstants.CONTACT} element={<Contact />} />
            
            
            <Route path={RouteConstants.ERROR} element={<ErrorPages/>} /> 
            {/* <Route path={RouteConstants.ERROR} element={<PifsFilter />} /> */}

            {/* <Route path={RouteConstants.REGISTER} element={<ErrorPage />} /> */}


          </Routes>
        </BrowserRouter>
      </div>
      <div className=" footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;


export class RouteConstants {
  public static HOME_PAGE: string = "/";
  public static ALT_LOGIN: string = "/alt_login";
  public static LOGIN: string = "login";
  public static ROB: string = "rob";
  public static AUTH: string = "/auth";
  public static SUPPORT: string = "/support";
  public static MY_USAID: string = "/myusaid";
  public static ABOUT: string = "/about";
  public static HELP: string = "/help";

  public static REVET_SUBMISSION: string = "/revet";
  public static REVET_PRIME_AWARD: string = "/revet/prime";  
  public static REVET_SUBAWARD: string = "/revet/sub";
  public static REVET_NON_AWARD: string = "/revet/non";

  public static REVET_SUBMISSION_AWARD_INFO: string = "/revet/awardinfo";
  public static REVET_SUBMISSION_ORGANIZATION: string = "/revet/organization";  
  public static REVET_SUBMISSION_KEY_INDIVIDUAL: string = "/revet/keyindividual";
  public static REVET_SUBMISSION_REVIEW: string = "/revet/review";
  public static REVET_SUBMISSION_AWARDEE: string = "/revet/awardee";



  public static NON_AWARD: string = "/nonaward";
  public static NON_AWARD_INFO: string = "/nonaward/awardinfo";
  public static NON_AWARD_ORGANIZATION: string = "/nonaward/organization";
  public static NON_AWARD_KEY_INDIVIDUAL: string = "/nonaward/keyindividual";
  public static NON_AWARD_REVIEW: string = "/nonaward/review";
  public static NON_AWARD_AWARDEE: string = "/nonaward/awardee";


  public static PRIME_AWARD: string = "/primeaward";
  public static PRIME_AWARD_INFO: string = "/primeaward/awardinfo";
  public static PRIME_AWARD_ORGANIZATION: string = "/primeaward/organization";
  public static PRIME_AWARD_KEY_INDIVIDUAL: string = "/primeaward/keyindividual";
  public static PRIME_AWARD_REVIEW: string = "/primeaward/review";
  public static PRIME_AWARD_AWARDEE: string = "/primeaward/awardee";

  public static SUB_AWARD: string = "/subaward";


  public static SUB_AWARD_INFO: string = "/subaward/awardinfo";
  public static SUBAWARD_ORGANIZATION: string = "/subaward/organization";
  public static SUBAWARD_KEY_INDIVIDUAL: string = "/subaward/keyindividual";
  public static SUBAWARD_REVIEW: string = "/subaward/review";
  public static SUBAWARD_AWARDEE: string = "/subaward/awardee";


  public static REGISTER: string = "/register";
  public static SUCCESS: string = "/success";
  public static DASHBOARD: string = "/dashboard";
  public static PRIVACY: string = "/privacy";
  public static CONTACT: string = "/contact";
  public static ERROR: string = "*";
}

export class AwardTypes {
  public static PRIME_AWARD: string = "primeaward";
  public static NON_AWARD: string = "nonaward";
  public static SUB_AWARD: string = "subaward";
}

export class FormTypes {
  public static AWARD_INFO: string = "awardinfo";
  public static ORG_INFO: string = "organization";
  public static INDIVIDUAL_INFO: string = "keyindividual";
  public static REVIEW:string="review"
}