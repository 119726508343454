import React, { Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './Header.css';
import PVSportalLogo from '../../../assets/images/PVSportalLogo.png';
import USAID_Logo from '../../../assets/images/USAID_Logo.png';
import { RouteConstants } from "../../../App";
import axios from "axios";
import { useState } from "react";


export default function Header(){

    const logoutURL: string = process.env.REACT_APP_API_URL + "/login/logout";
    const[cookie, setCookie] = useState("");
    let location: string = window.location.href.split('/').slice(-1)[0];

    return(
        <div className="d-flex header row">
            <table style={{backgroundColor: "#002f6c"}}>
                <tr>
                    <td rowSpan={2}><a href={RouteConstants.DASHBOARD}><img  src={USAID_Logo} alt="USAID LOGO"/></a></td>
                    <td><div style={{ display:"flex", justifyContent: "flex-end"}}>
                        <a  href={RouteConstants.DASHBOARD}> <img src="PVS_logo.png" height={"80px"} width={"175px"} alt="PVSPortal Logo"/></a>
                        </div></td>
                </tr>
                <tr>
                <td>
                    <div style={{ display:"flex", justifyContent: "flex-end", marginRight: "15px"}}>
                    <HeaderLinks label=" About " link={RouteConstants.ABOUT}/>
                    { (location.length!==0 && location !== RouteConstants.ABOUT.substring(1)) && <HeaderLinks label=" | Help | " link={RouteConstants.HELP}/>}
                    { (location.length!==0 && location !== RouteConstants.ABOUT.substring(1)) && <HeaderLinks label=" Contact Us  " link={RouteConstants.CONTACT}/>}
                    { (location.length!==0 && location !== RouteConstants.ABOUT.substring(1)) && <HeaderLinks label=" | Log Out" link={RouteConstants.HOME_PAGE}/>}
                    </div>
                </td>
                </tr>
            </table>
        </div>

    );
   

function HeaderLinks(props: { label: string; link: string }) {

 
        const handleLogOut = async () => {
            if(props.label === " Log Out"){
            try{
                const response = await axios.get(logoutURL, {
                   
                });
                const responseData = response.data;
                    setCookie(responseData);
                console.log('Cleared Cookies', response)
            } catch(error) {
                console.error('Error clearing cookie')
            }
        }
        }

      return (

        <div className="menulinks">
          <a href={props.link} onClick={handleLogOut}> &nbsp; {props.label} </a>
        </div>
    );
}
}

function resetApp() {
    throw new Error("Function not implemented.");
}
