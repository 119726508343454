import { ICitizenship } from "../Interfaces/ICitizenship";


export const filterEmptyCitizenship= 
     function(citList:ICitizenship[]){
        console.log("start" + citList.length)
       const result = citList.filter(x=>filterEmptyCitizenshipUtility(x))
       console.log("end" + result);
       return result;
    }

function filterEmptyCitizenshipUtility(x:ICitizenship)
{

    console.log(x.countryOfCitizenship);
    const isCountryOfCitEmpty:boolean=(x.countryOfCitizenship!=="")

    const isGovernmentIssuedPhotoId:boolean=(x.governmentIssuedPhotoId!=="")

    if(isCountryOfCitEmpty&&isGovernmentIssuedPhotoId){
        return true;
    }
    else{
        return false;
    }

}

//check strings
export const checkNullValue=(x:string|boolean|number):any=> x===null?"":x;

export const checkNullObject=(x:any):boolean=> !(x===null);


