import { useState } from "react";
import "./OrganizationInfo.css";
import { IcontrolAttributes } from "../../../App.types";
import { ICountry } from "../../../Interfaces/ICountry";
import HelpDialog from "../../common/HelpDialog";
import { PropaneSharp } from "@mui/icons-material";

export default function AdditionalPhoneNumber(prop: {
  title: string;
  controlAttributes: IcontrolAttributes;
  isRedStarNeeded: boolean;
  countryData:ICountry[];
  isOrg: boolean;
  countryCode: string
  countryCodeOnChnage: any;
  disabled:boolean;
}) {
  var [showAdditionalPhoneNumber, setshowAdditionalPhoneNumber] =
    useState(false);

    const [ phoneNum, setPhoneNum] = useState('');

    // remove countries with not country codes
    const filteredCountryCode =  prop.countryData?.filter(x=>x.country_code);


    let orgPhoneText = 'Country code and area/city code and full phone number\n';
        orgPhoneText += ' of the Organization, if dialed from outside of the country.'



  return (
    <div className="mt-3">
        <div className="col">
          {/* <label className="label-div asterisk form-label mb-1">{prop.title}</label> */}
          <label className={`ms-1 ${prop.isRedStarNeeded? 'asterisk': '' }`}>{prop.title} </label>
          { prop.isOrg && (<HelpDialog
                title={"Organization Phone Number"}
                context={orgPhoneText}
                />)
          }
        </div>
      
      <div className="row">
        <div className="col">
          <select className="form-select input-group-lg" style={{ width: "100%" }} 
            onChange={prop.countryCodeOnChnage} 
            value={prop.countryCode}
            disabled={prop.disabled}
            defaultValue="">
          <option value="" disabled> Code </option>
          {filteredCountryCode?.map((item, index) => (
            <option key={index} value={item.country_code}>{item.country_name}  ({item.country_code})</option>))}
          </select>
        </div>
        <div className="col">
          <input className="form-control input-group-lg"
             style={{ width: "100%" }}
             key="phone1"
            maxLength={50}
            placeholder="000-000-0000"
            type="text"
            onChange={prop.controlAttributes.primaryOnChange}
            value={prop.controlAttributes.primaryTextValue}
            disabled={prop.disabled}
          />
        </div>

        {/* <div className="col-4"></div> */}
      </div>
    </div>
  );
}
