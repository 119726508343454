import "bootstrap/dist/css/bootstrap.min.css";
import "./TestDashboard2.css";

import PIFDashboard from "./Dashboard2";
import "rsuite/dist/rsuite.min.css";
import './Dashboard.css';
import {  useNavigate } from "react-router-dom";
import { useNavStore } from "../../stores/NavStore";
import { authStore } from "../../stores/AuthStore";
import { useEffect, useState } from "react";
import { RouteConstants } from "../../../App";
import { useCookies } from "react-cookie";
import { useFormProps } from "../../stores/FormProperties";
import { AWARD_TYPE } from "../../../Interfaces/enums";



// Object for Top Left nav links
const navLinks =
{
  linkState: '',
  pageTitle: "PVSportal",
  breadCrumbTextLink: '',
  homeLinkText: '',
};

// handle navigation links
const handleNav = (e: Event, link: string) => {
  e.preventDefault();
  window.location.reload();

  const element = e.target as HTMLInputElement;
  // ensure link is not hidden
  element.hidden = false;
  // change pageTitle link in breadcrumbs

}

const handleBtnClick = (link: string) => {

}



const TestDashboard2 = () => {

  const { clearNavStore, updateAwardType } = useNavStore();
  const { clearFormProperties, formProperties,updateFormAwardType} = useFormProps();
  const { details } = authStore();
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['PF']);
  useEffect(() => {

    if (cookies) {
      console.log("Cookie details ", cookies);
      removeCookie("PF");
    };

    clearNavStore();
    clearFormProperties();

  }, []);

  return (
    <div>
      <div>
          <p className="text-center"> Welcome  {details?.firstName} {details?.lastName}</p>
        </div>
      <table>
        <tr>
          <td><h3 className="dash-title">Select An Award Type</h3></td>
        </tr>
        <tr>
          <td>
            <div className="dash-background">
              <div className="d-flex justify-content-start" style={{margin:"20px"}}>
              <button type="button" className="btn-style" onClick={() => {
              clearNavStore();
              clearFormProperties();
              updateAwardType("primeaward");
              updateFormAwardType(AWARD_TYPE.PRIME_AWARD);
              navigate(RouteConstants.PRIME_AWARD_INFO);
            }}>
              <img src="Prime_Tile.png"/>
            </button>
            <button type="button" className="btn-style" onClick={() => {
                  clearNavStore();
                  clearFormProperties();
                  updateAwardType("subaward");
                  updateFormAwardType(AWARD_TYPE.SUB_AWARD);
                  navigate(RouteConstants.SUB_AWARD);
                }}>
              <img src="Subaward_Tile.png"/>
            </button>
            <button type="button" className="btn-style" onClick={() => {
              clearNavStore();
              clearFormProperties();
              updateAwardType("nonaward");
              updateFormAwardType(AWARD_TYPE.NON_AWARD);
              navigate(RouteConstants.NON_AWARD);
            }}>
              <img src="Non_award_tile.png"/>
            </button>
            <button type="button" className="btn-style" onClick={() => {
              clearNavStore();
              clearFormProperties();
              updateAwardType("revet");
              navigate(RouteConstants.REVET_SUBMISSION);
            }}>
              <img src="Re_Vet_tile.png"/>
            </button>
              </div>            
            </div>
          </td>
          <td style={{width:"50%"}}></td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td><h3 className="dash-title">PVSportal Dashboard</h3></td>
        </tr>
        <tr>
          <td colSpan={2}>
          <div className="dash-background w-100 text-center">
            <PIFDashboard />
          </div>
          </td>
        </tr>
      </table>
    </div>
  )
}

export default TestDashboard2;
