import "bootstrap/dist/css/bootstrap.min.css";
import {Nav, Navbar, Container} from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import "./NavMenu.css";
import { auto } from "@popperjs/core";
import { RouteConstants } from "../../../App";
import { useNavStore } from "../../stores/NavStore";
import { useState } from "react";
import { convertCompilerOptionsFromJson } from "typescript";


export default function BreadCrumps (prop: any) {

    const navigate = useNavigate();
    const location = useLocation();
    const pageFlow = location.state?.pageFlow;
    var {storeFormType,updateFormType,awardType} = useNavStore();
    var isDisabled = false;
    var isRevetFlow = false;
    var linkText = (awardType === "subaward") ? "Subawardee" : (awardType === "primeaward") ? "Primeawardee" : (awardType === "nonaward") ? "Non-Awardee" : null;

    storeFormType = (storeFormType==="organization") ? "Organization" : (storeFormType==="individual") ? "Individual" : "";
        var linkText = (awardType === "subaward") ? "Subawardee" : (awardType === "primeaward") ? "Primeawardee" : (awardType === "nonaward") ? "Non-Awardee" : (awardType ==="revet") ? "Re-Vetting": null;

    // var navLink = RouteConstants.SUB_AWARD;
    var navLink = (awardType === "revet") ? RouteConstants.REVET_SUBMISSION : RouteConstants.SUB_AWARD;

    if(prop.linkTitle){
      storeFormType = prop.linkTitle;
      isDisabled = true;
    }

    if(awardType === "revet"){
      isRevetFlow = true;
      isDisabled = true;
      // linkText = "Re-Vetting";
      // storeFormType = "revet";
    }

    if (pageFlow === "revet") {
      isRevetFlow = true;
      // linkText = "Re-Vetting";
    }

    return (
      <>
        <Navbar collapseOnSelect  expand="md" variant="light" role="navigation"> 
          <Container fluid>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className="me-auto mt-0 content navbar-left navigation" >
                <Nav.Link  className="link-primary breadcrumb-item" onClick={()=>
                  navigate(RouteConstants.DASHBOARD)
                }> PVS Portal</Nav.Link>
                {/* Show back link if Revet Flow */}
                {isRevetFlow && <Nav.Link  className="link-primary no-breadcrumb breadcrumb-item  text-capitalize"  
                  style={{display: isRevetFlow ? "block" : "none"}}     
                
                  onClick={()=> {
                    updateFormType("");
                  navigate(RouteConstants.REVET_SUBMISSION)}
                  
                  }> Re-Vetting </Nav.Link>}
                <Nav.Link  className="link-primary breadcrumb-item text-capitalize" 
                style={{display: isDisabled ? "none" : "block"}}
                onClick={()=>{
                  updateFormType("");
                  navigate(navLink)}}> {linkText} </Nav.Link>
                  
                {storeFormType && <Nav.Link href="#" className="breadcrumb-item" style={{color:"#656469"}} aria-current="page" disabled > {storeFormType} </Nav.Link>}
              </Nav>
              
            </Navbar.Collapse>
          </Container>
        </Navbar>
    </> 
    )
  }

