import { useEffect, useState } from "react";
import "../../common/Tiles/Tiles.css";
import CitizenshipTemplate from "./CitizenshipTemplate";
import HelpDialog from "../../common/HelpDialog";
import { IOtherCitizenships } from "../../../App.types";
import { ICitizenship } from "../../../Interfaces/ICitizenship";

export default function DualCitizenship(prop: {
  hasDualCitizenship: string;
  title: string;
  selectedFiles: any;
  setSelectedFiles: any;
  setCountryData:any;
  setIdentityData:any;
  value:ICitizenship[];
  disabled: boolean;
  onChange:any
}) {




  var [showOther, setShowOther] = useState(false);
  var [show2ndOther, setShow2ndOther] = useState(false);
  var [show3rdOther, setShow3rdOther] = useState(false);
  var [show4thOther, setShow4thOther] = useState(false);
  var [show5thOther, setShow5thOther] = useState(false);

  var isDualRequired: boolean = prop.hasDualCitizenship === "Y";

  let textDualCitizenship = 'All fields must be completed:\n';
  textDualCitizenship += '- Dual Country of Citizenship (additional country of citizenship not\n';
  textDualCitizenship += ' listed in Country of Citzenship above\n';
  textDualCitizenship += '- ID country of issuance (country that issued the additional citizenship ID)\n';
  textDualCitizenship += '- Government-issued Photo ID Type (type of additional identification\n'
  textDualCitizenship += ' example: passport\n';
  textDualCitizenship += '- Complete Government-issued Photo ID Number (identification number\n';
  textDualCitizenship += ' from the additional government issued ID)';

  var [show2ndOther, setShow2ndOther] = useState(false);
  var [show3rdOther, setShow3rdOther] = useState(false);
  var [show4thOther, setShow4thOther] = useState(false);
  var [show5thOther, setShow5thOther] = useState(false);


  


  const onCitizenshipChange = function(cit:ICitizenship){
   
    console.log("CIT CHANGE detected");
    console.log(cit.countryOfCitizenship);

    console.log(cit.elementId);
    const element=cit.elementId;
    prop.value[element].countryOfCitizenship=cit?.countryOfCitizenship;
    prop.value[element].governmentIdentificationT=cit?.governmentIdentificationT;
    prop.value[element].governmentIssuedPhotoId=cit?.governmentIssuedPhotoId;
    prop.value[element].countryIssuedBy= cit?.countryIssuedBy;
    prop.value[element].individualId= cit?.individualId;
    prop.value[element].isDualCitizen= cit?.isDualCitizen;
    prop.value[element].elementId= cit?.elementId;
    prop.value[element].usOrNonUs= cit?.usOrNonUs;  

    prop.onChange(prop.value);

  }


 
    
  

  return (
    <>
      {isDualRequired && (
        <>
          <div className="textFormat row mt-3">
            <div className="col-7">
              <label className="mb-1 ms-1">{prop.title}</label>
              <HelpDialog
                title={"Dual Citizen"}
                context={textDualCitizenship}
              />        
            </div>
          </div>
          <CitizenshipTemplate
            countryTitle={"Country of Citizenship"}
            typeTitle={"Government Identification Type"}
            photoIdTitle={"Government-issued Photo ID #"}
            issuanceTitle={"ID Country of Issuance"}
            selectedFiles={prop.selectedFiles}
            setSelectedFiles={prop.setSelectedFiles}
            setCountryData={prop.setCountryData}
            setIdentityData={prop.setIdentityData}
            value={prop.value[0]}
            onChange={onCitizenshipChange}
            disabled={prop.disabled}
          />
          <div className="col-4 ms-auto label-div">
            <span
              style={{ color: "#0164B9" }}
              onClick={() => setShowOther((showOther = true))}
            >
              + Add Additional Citizenship
            </span>
          </div>
          {showOther && (
            <>
              <div className="textFormat row">
                <div className="col-4">
                  <label className="mb-1 ms-1">{prop.title}</label>
                </div>                
              </div>
              <CitizenshipTemplate
                countryTitle={"Country of Citizenship"}
                typeTitle={"Government Identification Type"}
                photoIdTitle={"Government-issued Photo ID #"}
                issuanceTitle={"ID Country of Issuance"}
                selectedFiles={prop.selectedFiles}
                setSelectedFiles={prop.setSelectedFiles}
                setCountryData={prop.setCountryData}
                setIdentityData={prop.setIdentityData}
                value={prop.value[1]}
                onChange={onCitizenshipChange}
                disabled={prop.disabled}
              />
              <div className="col-6 ms-auto label-div">
                <span
                  style={{ color: "#0164B9", marginRight: "5em" }}
                  onClick={
                    () => {
                      setShowOther((showOther = false))
                    }
                  }
                >
                  - Remove Citizenship
                </span>                
                <span
                  style={{ color: "#0164B9"}}
                  onClick={() => setShow2ndOther((show2ndOther = true))}
                >
                  + Add Additional Citizenship
                </span>
              </div>              
            </>
          )}
          {show2ndOther && (
            <>
              <div className="textFormat row">
                <div className="col-5 ms-4">
                  <label className="mb-1 ms-1">{prop.title}</label>
                </div>                
              </div>
              <CitizenshipTemplate
                countryTitle={"Country of Citizenship"}
                typeTitle={"Government Identification Type"}
                photoIdTitle={"Government-issued Photo ID #"}
                issuanceTitle={"ID Country of Issuance"}
                selectedFiles={prop.selectedFiles}
                setSelectedFiles={prop.setSelectedFiles}
                setCountryData={prop.setCountryData}
                setIdentityData={prop.setIdentityData}
                disabled={prop.disabled}

              />
              <div className="col-6 ms-auto label-div">
                <span
                  style={{ color: "#0164B9", marginRight: "5em" }}
                  onClick={() => setShow2ndOther((show2ndOther = false))}
                >
                  - Remove Citizenship
                </span>              
                <span
                  style={{ color: "#0164B9" }}
                  onClick={() => setShow3rdOther((show3rdOther = true))}
                >
                  + Add Additional Citizenship
                </span>
              </div>

            </>
          )}
          {show3rdOther && (
            <>
              <div className="textFormat row">
                <div className="col-4">
                  <label className="mb-1 ms-1">{prop.title}</label>
                </div>
              </div>
              <CitizenshipTemplate
                countryTitle={"Country of Citizenship"}
                typeTitle={"Government Identification Type"}
                photoIdTitle={"Government-Issued Photo ID #"}
                issuanceTitle={"ID Country of Issuance"}
                selectedFiles={prop.selectedFiles}
                setSelectedFiles={prop.setSelectedFiles}
                setCountryData={prop.setCountryData}
                setIdentityData={prop.setIdentityData}
                disabled={prop.disabled}
              />
              <div className="col-6 ms-auto label-div">
                <span
                  style={{ color: "#0164B9", marginRight: "5em" }}
                  onClick={() => setShow3rdOther((show3rdOther = false))}
                >
                  - Remove Citizenship
                </span>              
                <span
                  style={{ color: "#0164B9" }}
                  onClick={() => setShow4thOther((show4thOther = true))}
                >
                  + Add Additional Citizenship
                </span>
              </div>
            </>
          )}
          {show4thOther && (
            <>
              <div className="textFormat row">
                <div className="col-4">
                  <label className="mb-1 ms-1">{prop.title}</label>
                </div>
              </div>
              <CitizenshipTemplate
                countryTitle={"Country of Citizenship"}
                typeTitle={"Government Identification Type"}
                photoIdTitle={"Government-issued Photo ID #"}
                issuanceTitle={"ID Country of Issuance"}
                selectedFiles={prop.selectedFiles}
                setSelectedFiles={prop.setSelectedFiles}
                setCountryData={prop.setCountryData}
                setIdentityData={prop.setIdentityData}
                disabled={prop.disabled}
              />
              <div className="col-6 ms-auto label-div">
                <span
                  style={{ color: "#0164B9", marginRight: "5em" }}
                  onClick={() => setShow4thOther((show4thOther = false))}
                >
                  - Remove Citizenship
                </span>
                <span
                  style={{ color: "#0164B9"}}
                  onClick={() => setShow5thOther((show5thOther = true))}
                >
                  + Add Additional Citizenship
                </span>
              </div>
            </>
          )}
          {show5thOther && (
            <>
              <div className="textFormat row">
                <div className="col-7">
                  <label className="mb-1 ms-1">{prop.title}</label>
                </div>
              </div>
              <CitizenshipTemplate
                countryTitle={"Country of Citizenship"}
                typeTitle={"Government Identification Type"}
                photoIdTitle={"Government-issued Photo ID #"}
                issuanceTitle={"ID Country of Issuance"}
                selectedFiles={prop.selectedFiles}
                setSelectedFiles={prop.setSelectedFiles}
                setCountryData={prop.setCountryData}
                setIdentityData={prop.setIdentityData}
                disabled={prop.disabled}
              />
              <div className="col-3 ms-auto label-div">
                <span
                  style={{ color: "#0164B9" }}
                  onClick={() => setShow5thOther((show5thOther = false))}
                >
                  - Remove Citizenship
                </span>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
