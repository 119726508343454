export enum PARTNER_AWARD_TYPE {
    INDIVIDUAL ='pvs2_pif_individual',
    ORGANIZATION ='pvs2_pif_organization',
}

export enum AWARD_TYPE {
    PRIME_AWARD ='primeaward',
    SUB_AWARD ='subaward',
    NON_AWARD='nonaward',
    REVET_SUBMISSION='revet'
}
